
.header{
    width: 100%;
    background-color: rgb(100, 44, 144);
    height: 6vh;
}

/* .mainbox{
    background-image: url('../images/LoginBackground.png');
} */
.innerBox{
    width:  540px;
    padding: 0.8rem!important;
}

.innerBoxSub{
    width:  540px;
    padding: 1.5rem!important;
}
.answerBtn{
    width: 20%;
    height: 55px;
    color: #642c90;
    font-size: large;
}
 .submitBtn{
    width: 100%;
 }
.btn-outline-secondary{
    color: #6A3187 !important;
    border-color: #6A3187 !important;
}
.btn:hover {
    color: white !important;
    border-color: #6A3187 !important;
}
.number{
    color: #6A3187;
    font-size: 30px;
}
.timer{
    color: #6A3187;
    font-size: 25px;
}
.List{
    list-style-type: upper-alpha;
}

.btn-secondary {
    color: #fff;
    background-color: #6A3187 !important;
    /* border-color:solid 5px #341942 !important; */
    border:solid 2px #341942 !important;
}
.question-img{
    width: 100%;
}
.cardBox{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 0 4px rgb(0 0 0 / 35%) !important;
    border-radius: 15px !important;
}
#AnsButoon:hover{
background-color: #6A3187;
}

.answerBtn1{
    width: 20%;
    height: 55px;
    color: #642c90;
    background-color: #6A3187;
    font-size: large;
}

.btn1{
    border: 1px solid #642c90;
    box-sizing: border-box;
    border-radius: 5px;
    width: 20%;
    background-color: #fff;
    height: 55px;
    color: #642c90;
    font-size: large;
}

.btn2{
    color: #fff;
    background-color: #642c90;
    box-sizing: border-box;
    border-radius: 5px;
    width: 20%;
    height: 55px;
    font-size: large;
}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

.solution-div {
    border-bottom: 1px solid #642c90;
    font-size: medium;
    margin-top: 5px;
}